import Vue from "vue";
import Vuex from "vuex";
import vuexPersist from "vuex-persist";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    token: '',
    userlist: [],
    paper_id:'',
    prov_id:'',
    openShow:true,
  },
  mutations: {
    addtoken(state, token) {
      state.token = token
    },
    adduser(state, user) {
      state.userlist=user
    },
    addpaper(state,id){
      state.paper_id=id
    },
    addprov(state,id){
      state.prov_id=id
    },
    addopenShow(state,olb){
      state.openShow=olb
    },
    deleteToken(state){
      state.token = ''
    }
  },
  actions: {},
  modules: {},
  plugins: [
    new vuexPersist({
      localstorage: window.localStorage,
    }).plugin,
  ],
});
