<template>
  <div class="main" id="app" >
    <!-- <login></login> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {
    login:()=> import("@/view/login/login.vue"),
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
.el-button:hover {
  color: #ff6244;
    border-color: #ffd0c7;
    background-color: #ffefec;
}
.is-checked{
        .el-checkbox__inner{
            background-color: #ff6244;
            border-color: #ff6244;
        }
        .el-checkbox__label{
            color: #ff6244;
    }
}
.el-checkbox__inner:hover {
    border-color: #ff6244;
}
.el-button--primary{
  color: #fff;
    background-color: #ff6244;
    border-color: #ff6244;
}
.el-button--primary:focus, .el-button--primary:hover {
    background: #ff8169;
    border-color: #ff8169;
    color: #fff;
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  .main{
    width: 100%;
    height: 100%;
  }
}

</style>
